@media (max-width: 500px) {
  .App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  #jumper {
    padding: 10px;
    margin: 20px 0px 0px 10px;
    background-color: inherit;
    width: 100vw;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    /* width: 500px;
    height: 100px; */
    white-space: nowrap;
    /* position: relative; */
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .jumper-link {
    margin: 0px 0px 0px 10px;
    align-items: center;
    font-size: 18px;
    display: inline-block;
    width: 25%;
  }
  /* .jumper-link:hover {
  margin: 0px 5px 5px 10px;
} */

  #skills-ul {
    list-style-type: disc;
  }

  #skills-ul li {
    display: inline-block;
    margin: 7px;
    padding: 6px 12px;
    color: black;
    background: #e4e4ea;
    list-style: none;
    cursor: default;
    font-size: 1em;
    opacity: 0.9;
  }

  #skills-ul li.big-skill {
    font-size: 1.4em;
    padding: 8px 16px;
    opacity: 1;
  }
  #skills-ul li.big-skill:hover {
    background-color: #9e8ede;
    color: white;
  }
  #skills-ul li.medium-skill {
    font-size: 1em;
    padding: 6px 12px;
    opacity: 0.9;
  }
  #skills-ul li.medium-skill:hover {
    color: white;
    background-color: #9e8ede;
  }
  #skills-ul li.small-skill {
    font-size: 0.7em;
    padding: 3px 6px;
    opacity: 0.5;
  }
  #skills-ul li.small-skill:hover {
    color: white;
    background-color: #9e8ede;
  }

  /* this used to be green before color scheme change, hence the className, but i have kept the className even with the change */
  .green-lantern:hover {
    color: #9400d3;
  }

  .section {
    border: 4px solid navy;
    /* margin: 20px 10px 10px 10px; */
    width: 80%;
    margin: 10px;
    background: #4b9cd3;
    box-shadow: -5px 5px 2px #7b68ee;
    padding: 10px;
    position: relative;
    border-radius: 15px;
  }

  h4 {
    font-size: 30px;
  }

  a:link,
  a:visited {
    color: #61dafb;
    text-decoration: none;
  }

  a:hover {
    color: magenta;
    text-decoration: underline;
  }

  a:active {
    color: lightgreen;
    text-decoration: none;
  }

  ul {
    text-align: left;
  }

  .edu-item {
    text-align: left;
    color: black;
    padding: 6px;
    margin: 10px 10px 10px 10px;
    border: 2px solid black;
    box-shadow: -2px 3px 1px #3457d5;
    background-color: #e4e4ea;
    position: relative;
    border-radius: 8px;
  }
  .edu-item:hover {
    box-shadow: -4px 6px 2px #00ffff;
    margin: 5px 5px 15px 15px;
    background-color: #3457d5;
    color: white;
  }
  .exp-item {
    color: black;
    text-align: left;
    padding: 6px;
    margin: 10px 10px 10px 10px;
    border: 2px solid black;
    box-shadow: -2px 3px 1px #3457d5;
    background-color: #e4e4ea;
    position: relative;
    border-radius: 8px;
  }
  .exp-item:hover {
    box-shadow: -4px 6px 2px #00ffff;
    margin: 5px 5px 15px 15px;
    background-color: #3457d5;
    color: white;
  }

  .item-ul {
    list-style-type: disc;
  }
  .item-ul li {
    display: inline-block;
    margin: 7px;
    padding: 6px 12px;
    color: white;
    background: #7b68ee;
    list-style: none;
    cursor: default;
    border: 1px solid black;
    font-size: 1em;
    opacity: 0.9;
  }
  /* e4e4ea */
  .project-card {
    padding: 15px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    background-color: #e4e4ea;
    color: black;
    box-shadow: -2px 3px 1px #3457d5;
    position: relative;
  }
  .project-card:hover {
    box-shadow: -4px 6px 2px #00ffff;
    margin: 5px 5px 15px 15px;
    background-color: #3457d5;
    color: white;
  }

  .project-links {
    padding: 6px;
    margin: 6px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  a.link.project-link {
    /* margin: 0px 0px 0px 5px; */
    margin: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
    align-self: center;
    font-size: inherit;
    /* color: inherit; */
  }

  .project-video {
    width: 70%;
    height: 315px;
  }

  .expand-button.item {
    font-size: 30px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    margin: 0px 8px 0px 0px;
  }
  .expand-button {
    font-size: 30px;
    padding: 5px;
    margin: 0px 8px 0px 0px;
  }
  .expand-button.item:hover {
    /* not sure why this doesn't work, it's a specificity thing, but have to investigate more */
    color: #00ffff;
  }

  .cursor {
    cursor: pointer;
  }

  .thumbnail {
    max-height: 300px;
    max-width: 300px;
  }

  h5 {
    font-size: 12px;
  }

  #contact-form {
    display: block;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 8px;
    padding: 15px;
    border: 3px solid #7b68ee;
    /* background-color: aqua; */
  }
  .contact-piece {
    width: 88%;
    line-height: 1.5;
    display: block;
    padding: 8px;
    margin: 3px;
    align-self: center;
  }
  #send-email-button {
    background-color: #4b9cd3;
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding: 5px;
    border-radius: 4px;
    align-self: center;
    width: 95%;
  }
  #send-email-button:hover {
    background-color: #9e8ede;
  }

  #footer {
    padding: 20px;
    margin: 20px 0px 60px 0px;
  }

  .edu-title {
    text-align: center;
    font-size: 25px;
  }

  .edu-dates,
  .edu-field {
    font-size: 18px;
  }
  .edu-details {
    font-size: 16px;
  }

  .exp-title {
    text-align: center;
  }
  .exp-dates,
  .exp-who {
    font-size: 18px;
  }
  .exp-details {
    font-size: 16px;
  }

  .heart:hover {
    color: red;
  }

  .desc-and-vid-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 7px 0px 0px 3px;
  }
  .project-description {
    justify-content: center;
    align-items: center;
    vertical-align: center;
    background-color: #7b68ee;
    color: white;
    width: 18%;
    max-height: 315;
    padding: 10px;
    margin: 7px;
    font-size: 10px;
    box-shadow: -2px 3px 1px black;
    border-radius: 2px;
  }
}
@media (min-width: 500px) {
  .App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  h1 {
    font-size: 75px;
  }

  #jumper {
    padding: 10px;
    margin: 20px 0px 0px 10px;
    background-color: inherit;
    width: 100vw;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .jumper-link {
    margin: 0px 0px 0px 10px;
    align-items: center;
  }
  /* .jumper-link:hover {
  margin: 0px 5px 5px 10px;
} */

  #skills-ul {
    list-style-type: disc;
  }

  #skills-ul li {
    display: inline-block;
    margin: 7px;
    padding: 6px 12px;
    color: black;
    background: #e4e4ea;
    list-style: none;
    cursor: default;
    font-size: 1em;
    opacity: 0.9;
  }

  #skills-ul li.big-skill {
    font-size: 1.4em;
    padding: 8px 16px;
    opacity: 1;
  }
  #skills-ul li.big-skill:hover {
    background-color: #9e8ede;
    color: white;
  }
  #skills-ul li.medium-skill {
    font-size: 1em;
    padding: 6px 12px;
    opacity: 0.9;
  }
  #skills-ul li.medium-skill:hover {
    color: white;
    background-color: #9e8ede;
  }
  #skills-ul li.small-skill {
    font-size: 0.7em;
    padding: 3px 6px;
    opacity: 0.5;
  }
  #skills-ul li.small-skill:hover {
    color: white;
    background-color: #9e8ede;
  }

  /* this used to be green before color scheme change, hence the className, but i have kept the className even with the change */
  .green-lantern:hover {
    color: #9400d3;
  }

  .section {
    border: 4px solid navy;
    /* margin: 20px 10px 10px 10px; */
    width: 80%;
    margin: 10px;
    background: #4b9cd3;
    box-shadow: -5px 5px 2px #7b68ee;
    padding: 10px;
    position: relative;
    border-radius: 15px;
  }

  h4 {
    font-size: 30px;
  }

  a:link,
  a:visited {
    color: #61dafb;
    text-decoration: none;
  }

  a:hover {
    color: magenta;
    text-decoration: underline;
  }

  a:active {
    color: lightgreen;
    text-decoration: none;
  }

  ul {
    text-align: left;
  }

  .edu-item {
    text-align: left;
    color: black;
    padding: 6px;
    margin: 10px 10px 10px 10px;
    border: 2px solid black;
    box-shadow: -2px 3px 1px #3457d5;
    background-color: #e4e4ea;
    position: relative;
    border-radius: 8px;
  }
  .edu-item:hover {
    box-shadow: -4px 6px 2px #00ffff;
    margin: 5px 5px 15px 15px;
    background-color: #3457d5;
    color: white;
  }
  .exp-item {
    color: black;
    text-align: left;
    padding: 6px;
    margin: 10px 10px 10px 10px;
    border: 2px solid black;
    box-shadow: -2px 3px 1px #3457d5;
    background-color: #e4e4ea;
    position: relative;
    border-radius: 8px;
  }
  .exp-item:hover {
    box-shadow: -4px 6px 2px #00ffff;
    margin: 5px 5px 15px 15px;
    background-color: #3457d5;
    color: white;
  }

  .item-ul {
    list-style-type: disc;
  }
  .item-ul li {
    display: inline-block;
    margin: 7px;
    padding: 6px 12px;
    color: white;
    background: #7b68ee;
    list-style: none;
    cursor: default;
    border: 1px solid black;
    font-size: 1em;
    opacity: 0.9;
  }
  /* e4e4ea */
  .project-card {
    padding: 15px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    background-color: #e4e4ea;
    color: black;
    box-shadow: -2px 3px 1px #3457d5;
    position: relative;
  }
  .project-card:hover {
    box-shadow: -4px 6px 2px #00ffff;
    margin: 5px 5px 15px 15px;
    background-color: #3457d5;
    color: white;
  }

  .project-links {
    padding: 6px;
    margin: 6px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  a.link.project-link {
    margin: 0px 0px 0px 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    align-self: center;
    font-size: 15px;
    /* color: inherit; */
  }

  .project-video {
    width: 70%;
    height: 315px;
  }

  .expand-button.item {
    font-size: 30px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    margin: 0px 8px 0px 0px;
  }
  .expand-button {
    font-size: 30px;
    padding: 5px;
    margin: 0px 8px 0px 0px;
  }

  .cursor {
    cursor: pointer;
  }

  .thumbnail {
    max-height: 300px;
    max-width: 300px;
  }

  #contact-form {
    display: block;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 8px;
    padding: 15px;
    border: 3px solid #7b68ee;
    /* background-color: aqua; */
  }
  .contact-piece {
    width: 95%;
    line-height: 1.5;
    display: block;
    padding: 8px;
    /* margin: 3px 3px 3px 30px; */
    align-self: center;
  }
  #send-email-button {
    background-color: #4b9cd3;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border-radius: 4px;
    align-self: center;
    width: 99%;
  }
  #send-email-button:hover {
    background-color: #9e8ede;
  }

  #footer {
    padding: 20px;
    margin: 20px 0px 60px 0px;
  }

  .edu-title {
    text-align: center;
    font-size: 25px;
  }

  .edu-dates,
  .edu-field {
    font-size: 18px;
  }
  .edu-details {
    font-size: 16px;
  }

  .exp-title {
    text-align: center;
  }
  .exp-dates,
  .exp-who {
    font-size: 18px;
  }
  .exp-details {
    font-size: 16px;
  }

  .heart:hover {
    color: red;
  }

  .desc-and-vid-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: 8px 0px 0px 15px;
  }
  .project-description {
    justify-content: center;
    align-items: center;
    vertical-align: center;
    background-color: #7b68ee;
    color: white;
    width: 20%;
    max-height: 315;
    padding: 10px;
    margin: 12px;
    font-size: 20px;
    box-shadow: -2px 3px 1px black;
    border-radius: 2px;
  }
}
